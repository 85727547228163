import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
} from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import { useEffectAsync } from '../reactHelper';
import { useTranslation } from '../common/components/LocalizationProvider';
import CollectionFab from '../settings/components/CollectionFab';
import CollectionActions from '../settings/components/CollectionActions';
import TableShimmer from '../common/components/TableShimmer';
import SearchHeader, {
  filterByKeyword,
} from '../settings/components/SearchHeader';
import { usePreference } from '../common/util/preferences';
import { formatTime } from '../common/util/formatter';
import { useDeviceReadonly } from '../common/util/permissions';
import useSettingsStyles from '../settings/common/useSettingsStyles';
import KeyPageLayout from '../common/components/KeyPageLayout';

const KeyDevicesPage = () => {
  const classes = useSettingsStyles();
  const navigate = useNavigate();
  const t = useTranslation();

  const groups = useSelector((state) => state.groups.items);

  const hours12 = usePreference('twelveHourFormat');

  const deviceReadonly = useDeviceReadonly();

  const [timestamp, setTimestamp] = useState(Date.now());
  const [items, setItems] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [loading, setLoading] = useState(false);

  useEffectAsync(async () => {
    setLoading(true);
    try {
      const response = await fetch('/api/devices');
      if (response.ok) {
        setItems(await response.json());
      } else {
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, [timestamp]);

  const actionConnections = {
    key: 'connections',
    title: t('sharedConnections'),
    icon: <LinkIcon fontSize="small" />,
    handler: (deviceId) => navigate(`/devices/${deviceId}/connections`),
  };

  return (
    <KeyPageLayout>
      <div className={classes.tableWrapper}>
        <SearchHeader keyword={searchKeyword} setKeyword={setSearchKeyword} />
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{t('sharedName')}</TableCell>
              <TableCell>{t('deviceIdentifier')}</TableCell>
              <TableCell>{t('groupParent')}</TableCell>
              <TableCell>{t('sharedPhone')}</TableCell>
              <TableCell>{t('deviceModel')}</TableCell>
              <TableCell>{t('deviceContact')}</TableCell>
              <TableCell>{t('userExpirationTime')}</TableCell>
              <TableCell className={classes.columnAction} />
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading ? (
              items.filter(filterByKeyword(searchKeyword)).map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.uniqueId}</TableCell>
                  <TableCell>
                    {item.groupId ? groups[item.groupId]?.name : null}
                  </TableCell>
                  <TableCell>{item.phone}</TableCell>
                  <TableCell>{item.model}</TableCell>
                  <TableCell>{item.contact}</TableCell>
                  <TableCell>
                    {formatTime(item.expirationTime, 'date', hours12)}
                  </TableCell>
                  <TableCell className={classes.columnAction} padding="none">
                    <CollectionActions
                      itemId={item.id}
                      editPath="/devices"
                      endpoint="devices"
                      setTimestamp={setTimestamp}
                      customActions={[actionConnections]}
                      readonly={deviceReadonly}
                    />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableShimmer columns={7} endAction />
            )}
          </TableBody>
        </Table>
        <CollectionFab editPath="/settings/device" />
      </div>
    </KeyPageLayout>
  );
};

export default KeyDevicesPage;

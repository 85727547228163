import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import Logo from '../resources/images/traci-full.png';

const useStyles = makeStyles(() => ({
  image: {
    alignSelf: 'center',
    maxWidth: '320px',
    maxHeight: '120px',
    width: 'auto',
    height: 'auto',
  },
}));

const LogoImage = () => {
  const classes = useStyles();

  const logo = useSelector((state) => state.session.server.attributes?.logo);

  if (logo) {
    return <img className={classes.image} src={logo} alt="Traci Logo" />;
  }
  return <img className={classes.image} src={Logo} alt="Traci Logo" />;
};

export default LogoImage;

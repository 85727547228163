/* eslint-disable */
import React from 'react';
import SidebarNavLogo from './sidebarNav/sidebarNavLogo';
import { makeStyles } from '@mui/styles';
import ShareLocationOutlinedIcon from '@mui/icons-material/ShareLocationOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PersonPinOutlinedIcon from '@mui/icons-material/PersonPinOutlined';
import SendToMobileIcon from '@mui/icons-material/SendToMobile';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SidebarLogout from './sidebarNav/sidebarLogout';
import { devicesActions } from '../store';
import ReplayIcon from '@mui/icons-material/Replay';
import { useTranslation } from '../common/components/LocalizationProvider';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(3)} ${theme.spacing(1)}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    borderRight: `1px solid ${theme.palette.colors.gray}`,
  },
  iconsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '48px',
    height: '48px',
    color: theme.palette.colors.darkGray,
    '&:hover': {
      background: theme.palette.primary.light,
      borderRadius: theme.dimensions.borderRadiusSmall,
      color: theme.palette.colors.white,
      cursor: 'pointer',
    },
  },
  iconExtended: {
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(3),
    width: 'unset',
    height: '48px',
    textDecoration: 'none',
    gap: 12,
  },
  isActive: {
    color: theme.palette.primary.main,
    fontWeight: 600
  },
}));

const MainSidebarNav = ({ extended }) => {
  const classes = useStyles();
  const t = useTranslation();

  const dispatch = useDispatch();

  const userId = useSelector((state) => state.session.user.id);

  const handleShareLocationClick = () => {
    dispatch(devicesActions.setShowDevices(true));
  };

  return (
    <div className={classes.root}>
      <div className={classes.iconsWrapper} >
        {/* <div className={classes.icon} onClick={() => navigate('')}>
          <SidebarNavLogo />
        </div> */}
        <NavLink
          className={({ isActive }) =>
            `${classes.icon} ${isActive ? `${classes.isActive}` : ''} ${extended ? `${classes.iconExtended}` : ''}`
          }
          to="/"
          onClick={handleShareLocationClick}
        >
          <ShareLocationOutlinedIcon /> {extended && <p>{t('mapTitle')}</p>}
        </NavLink>
        <NavLink
          className={({ isActive }) =>
          `${classes.icon} ${isActive ? `${classes.isActive}` : ''} ${extended ? `${classes.iconExtended}` : ''}`
          }
          to="/replay"
        >
          <ReplayIcon /> {extended && <p>{t('reportReplay')}</p>}
        </NavLink>
        <NavLink
          className={({ isActive }) =>
          `${classes.icon} ${isActive ? `${classes.isActive}` : ''} ${extended ? `${classes.iconExtended}` : ''}`

          }
          to={'/reports/combined'}
        >
          <ArticleOutlinedIcon /> {extended && <p>{t('reportTitle')}</p>}
        </NavLink>
        <NavLink
          className={({ isActive }) =>
          `${classes.icon} ${isActive ? `${classes.isActive}` : ''} ${extended ? `${classes.iconExtended}` : ''}`

          }
          to="/devices"
        >
          <SendToMobileIcon /> {extended && <p>{t('deviceTitle')}</p>}
        </NavLink>
      </div>
      <div className={classes.iconsWrapper}>
        {/* <NavLink
          className={({ isActive }) =>
          `${classes.icon} ${isActive ? `${classes.isActive}` : ''} ${extended ? `${classes.iconExtended}` : ''}`

          }
          to="https://www.rentvisie.com"
        >
          <HelpOutlineOutlinedIcon />
        </NavLink> */}
        <NavLink
          className={({ isActive }) =>
          `${classes.icon} ${isActive ? `${classes.isActive}` : ''} ${extended ? `${classes.iconExtended}` : ''}`
          }
          to={`/users/${userId}`}
        >
          <PersonPinOutlinedIcon /> {extended && <p>{t('settingsUser')}</p>}
        </NavLink>
        <NavLink
          className={({ isActive }) =>
          `${classes.icon} ${isActive ? `${classes.isActive}` : ''} ${extended ? `${classes.iconExtended}` : ''}`
          }
          to="/settings/preferences"
        >
          <SettingsOutlinedIcon /> {extended && <p>{t('settingsTitle')}</p>}
        </NavLink>

        <div className={`${classes.icon} ${extended ? `${classes.iconExtended}` : ''}` }>
          <SidebarLogout /> {extended && <p>Logout</p>}
        </div>
      </div>
    </div>
  );
};

export default MainSidebarNav;

import { IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import LockIcon from '@mui/icons-material/Lock';

const GetLockStatus = ({ iccid }) => {
  const [status, setStatus] = useState(undefined);

  const getLockStatus = async () => {
    const response = await fetch(
      `https://api.eu.production.rentvisie.com/api/v2/traci/device-status/${iccid}`,
    );

    if (response.ok) {
      setStatus(await response.json());
    } else {
      setStatus(undefined);
      throw Error(await response.text());
    }
  };

  useEffect(() => {
    getLockStatus();
  }, [iccid]);
  return (
    <IconButton size="small">
      {status && status.lockStatus.toUpperCase() === 'LOCK' && (
        <LockIcon fontSize="small" />
      )}
    </IconButton>
  );
};

export default GetLockStatus;

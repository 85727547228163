import React from 'react';
import { useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@emotion/react';
import MainSidebarNav from '../../main/MainSidebarNav';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    display: 'flex',
  },
  sidebarWrapper: {
    height: '100%',
    display: 'flex',
  },
}));

const KeyPageLayout = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <div className={classes.root}>
      {desktop && (
        <div className={classes.sidebarNav}>
          <MainSidebarNav />
        </div>
      )}
      <div style={{ width: '100%', background: 'white' }}>{children}</div>
    </div>
  );
};

export default KeyPageLayout;

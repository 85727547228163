import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import LogoImage from './LogoImage';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoWrapper: {
    marginBottom: theme.spacing(2),
  },
  form: {
    maxWidth: theme.spacing(52),
    // padding: theme.spacing(5),
    minWidth: '320px',
  },
}));

const LoginLayout = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <main className={classes.root}>
      <div className={classes.logoWrapper}>
        <LogoImage color={theme.palette.secondary.contrastText} />
      </div>
      <div>
        <form className={classes.form}>{children}</form>
      </div>
    </main>
  );
};

export default LoginLayout;

export default {
  sidebarWidth: '28%',
  sidebarWidthTablet: '52px',
  sidebarWidthDesktop: '52px',
  drawerWidthDesktop: '360px',
  drawerWidthTablet: '320px',
  drawerHeightPhone: '250px',
  filterFormWidth: '160px',
  eventsDrawerWidth: '320px',
  bottomBarHeight: 56,
  popupMapOffset: 300,
  popupMaxWidth: 288,
  popupImageHeight: 144,
  borderRadiusSmall: '6px',
  borderRadiusLarge: '12px',
  boxShadow: '2px 2px 2px #f5f5f5',
};

/* eslint-disable */
import React, { useState, useCallback, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import DeviceList from './DeviceList';
import StatusCard from '../common/components/StatusCard';
import { devicesActions } from '../store';
import usePersistedState from '../common/util/usePersistedState';
import EventsDrawer from './EventsDrawer';
import useFilter from './useFilter';
import MainToolbar from './MainToolbar';
import MainMap from './MainMap';
import { useAttributePreference } from '../common/util/preferences';
import MainSidebarNav from './MainSidebarNav';
import CommandsPopup from '../custom/Commands';
import { IconButton } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  sidebarWrapper: {
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 3,
    height: `100%`,
    display: 'flex',
  },
  sidebarNav: {
    background: theme.palette.colors.grayLight,
    margin: theme.dimensions.sidebarPadding,
    position: 'relative',
  },
  sidebarExtend: {
    position: 'absolute',
    top: '50%',
    right: '0',
    transform: 'translate(20%, -50%)',
  },
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    pointerEvents: 'auto',
    background: theme.palette.colors.white,
    paddingBottom: theme.spacing(2),
    zIndex: 6,
    position: 'sticky',
  },
  footer: {
    pointerEvents: 'auto',
    zIndex: 5,
  },
  device: {
    [theme.breakpoints.up('md')]: {
      background: theme.palette.colors.white,
    },
    [theme.breakpoints.down('md')]: {
      background: 'transparent',
    },
    padding: `0 ${theme.spacing(2)}`,
    minWidth: '320px',
  },
  bottom: {
    display: 'flex',
    flex: 1,
  },
  contentMap: {
    pointerEvents: 'auto',
    gridArea: '1 / 1',
  },
  contentList: {
    pointerEvents: 'auto',
    gridArea: '1 / 1',
    zIndex: 4,
  },
}));

const MainPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();

  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  const mapOnSelect = useAttributePreference('mapOnSelect', true);

  const selectedDeviceId = useSelector((state) => state.devices.selectedId);
  const positions = useSelector((state) => state.session.positions);
  const [filteredPositions, setFilteredPositions] = useState([]);
  const selectedPosition = filteredPositions.find(
    (position) => selectedDeviceId && position.deviceId === selectedDeviceId
  );

  const [filteredDevices, setFilteredDevices] = useState([]);

  const [keyword, setKeyword] = useState('');
  const [filter, setFilter] = usePersistedState('filter', {
    statuses: [],
    groups: [],
  });
  const [filterSort, setFilterSort] = usePersistedState('filterSort', '');
  const [filterMap, setFilterMap] = usePersistedState('filterMap', false);

  const [devicesOpen, setDevicesOpen] = useState(desktop);
  const [eventsOpen, setEventsOpen] = useState(false);

  const onEventsClick = useCallback(() => setEventsOpen(true), [setEventsOpen]);

  const [extendSidebar, setExtendSidebar] = useState(false);

  useEffect(() => {
    if (!desktop && mapOnSelect && selectedDeviceId) {
      setDevicesOpen(false);
    }
  }, [desktop, mapOnSelect, selectedDeviceId]);

  useFilter(
    keyword,
    filter,
    filterSort,
    filterMap,
    positions,
    setFilteredDevices,
    setFilteredPositions
  );

  return (
    <div className={classes.root}>
      {desktop && (
        <div className={classes.sidebarWrapper}>
          <div
            className={classes.sidebarNav}
            style={{ width: !extendSidebar ? 'unset' : '100%' }}
          >
            <MainSidebarNav extended={extendSidebar}/>
            <div className={classes.sidebarExtend}>
              <IconButton
                color={theme.palette.colors.primaryLight}
                edge="start"
                sx={{ background: theme.palette.colors.grayLight }}
                onClick={() => setExtendSidebar(!extendSidebar)}
              >
                <ChevronRightIcon sx={{ transform: !extendSidebar ? '' : 'scaleX(-1)' }} />
              </IconButton>
            </div>
          </div>
          <div className={classes.sidebar}>
            <div className={classes.header}>
              <MainToolbar
                filteredDevices={filteredDevices}
                keyword={keyword}
                setKeyword={setKeyword}
                filter={filter}
                setFilter={setFilter}
                filterSort={filterSort}
                setFilterSort={setFilterSort}
                filterMap={filterMap}
                setFilterMap={setFilterMap}
              />
            </div>
            {selectedDeviceId && (
              <div className={classes.device}>
                <StatusCard
                  deviceId={selectedDeviceId}
                  position={selectedPosition}
                  onClose={() => dispatch(devicesActions.selectId(null))}
                  desktopPadding={theme.dimensions.drawerWidthDesktop}
                />
              </div>
            )}
            <div className={classes.bottom}>
              <DeviceList devices={filteredDevices} />
            </div>
          </div>
        </div>
      )}

      <MainMap
        filteredPositions={filteredPositions}
        selectedPosition={selectedPosition}
        onEventsClick={onEventsClick}
      />
      {!desktop && (
        <div style={{ position: 'absolute', top: 0, width: '80%' }}>
          <MainToolbar
            filteredDevices={filteredDevices}
            keyword={keyword}
            setKeyword={setKeyword}
            filter={filter}
            setFilter={setFilter}
            filterSort={filterSort}
            setFilterSort={setFilterSort}
            filterMap={filterMap}
            setFilterMap={setFilterMap}
          />
          <DeviceList devices={filteredDevices} />
          {selectedDeviceId && (
            <div className={classes.device}>
              <StatusCard
                deviceId={selectedDeviceId}
                position={selectedPosition}
                onClose={() => dispatch(devicesActions.selectId(null))}
                desktopPadding={theme.dimensions.drawerWidthDesktop}
              />
            </div>
          )}
        </div>
      )}

      {/* Events on Click */}
      <EventsDrawer open={eventsOpen} onClose={() => setEventsOpen(false)} />
    </div>
  );
};

export default MainPage;

import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Container,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import LinkField from '../common/components/LinkField';
import { useTranslation } from '../common/components/LocalizationProvider';
import { formatNotificationTitle } from '../common/util/formatter';
import useFeatures from '../common/util/useFeatures';
import KeyPageLayout from '../common/components/KeyPageLayout';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
  detailsRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
}));

const KeyDeviceConnectionsPage = () => {
  const classes = useStyles();
  const t = useTranslation();

  const navigate = useNavigate();

  const { id } = useParams();

  const features = useFeatures();

  return (
    <KeyPageLayout>
      <Container maxWidth="sm" className={classes.container}>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1">
              {t('sharedConnections')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <div className={classes.detailsRow}>
              <LinkField
                endpointAll="/api/geofences"
                endpointLinked={`/api/geofences?deviceId=${id}`}
                baseId={id}
                keyBase="deviceId"
                keyLink="geofenceId"
                label={t('sharedGeofences')}
                style={{ width: '100%' }}
              />
              <IconButton
                color="info"
                onClick={() => navigate('/geofences')}
                size="small"
              >
                <SettingsIcon />
              </IconButton>
            </div>
            <div className={classes.detailsRow}>
              <LinkField
                endpointAll="/api/notifications"
                endpointLinked={`/api/notifications?deviceId=${id}`}
                baseId={id}
                keyBase="deviceId"
                keyLink="notificationId"
                titleGetter={(it) => formatNotificationTitle(t, it)}
                style={{ width: '100%' }}
                label={t('sharedNotifications')}
              />
              <IconButton
                color="info"
                size="small"
                onClick={() => navigate('/settings/notifications')}
              >
                <SettingsIcon />
              </IconButton>
            </div>
            <div className={classes.detailsRow}>
              {!features.disableDrivers && (
                <>
                  <LinkField
                    endpointAll="/api/drivers"
                    endpointLinked={`/api/drivers?deviceId=${id}`}
                    baseId={id}
                    keyBase="deviceId"
                    keyLink="driverId"
                    style={{ width: '100%' }}
                    label={t('sharedDrivers')}
                  />
                  <IconButton
                    color="info"
                    size="small"
                    onClick={() => navigate('/settings/drivers')}
                  >
                    <SettingsIcon />
                  </IconButton>
                </>
              )}
            </div>
            <div className={classes.detailsRow}>
              {!features.disableComputedAttributes && (
                <>
                  <LinkField
                    endpointAll="/api/attributes/computed"
                    endpointLinked={`/api/attributes/computed?deviceId=${id}`}
                    baseId={id}
                    keyBase="deviceId"
                    keyLink="attributeId"
                    titleGetter={(it) => it.description}
                    style={{ width: '100%' }}
                    label={t('sharedComputedAttributes')}
                  />
                  <IconButton
                    color="info"
                    size="small"
                    onClick={() => navigate('/settings/attributes')}
                  >
                    <SettingsIcon />
                  </IconButton>
                </>
              )}
            </div>
            <div className={classes.detailsRow}>
              <LinkField
                endpointAll="/api/commands"
                endpointLinked={`/api/commands?deviceId=${id}`}
                baseId={id}
                keyBase="deviceId"
                keyLink="commandId"
                titleGetter={(it) => it.description}
                style={{ width: '100%' }}
                label={t('sharedSavedCommands')}
              />
              <IconButton
                color="info"
                size="small"
                onClick={() => navigate('/settings/commands')}
              >
                <SettingsIcon />
              </IconButton>
            </div>
            <div className={classes.detailsRow}>
              {!features.disableMaintenance && (
                <>
                  <LinkField
                    endpointAll="/api/maintenance"
                    endpointLinked={`/api/maintenance?deviceId=${id}`}
                    baseId={id}
                    keyBase="deviceId"
                    keyLink="maintenanceId"
                    style={{ width: '100%' }}
                    label={t('sharedMaintenance')}
                  />
                  <IconButton
                    color="info"
                    size="small"
                    onClick={() => navigate('/settings/maintenances')}
                  >
                    <SettingsIcon />
                  </IconButton>
                </>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
      </Container>
    </KeyPageLayout>
  );
};

export default KeyDeviceConnectionsPage;

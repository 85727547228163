import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { useTranslation } from './LocalizationProvider';
import { useCatch } from '../../reactHelper';

const useStyles = makeStyles(() => ({
  root: {
    '&:hover': {
      cursor: 'pointer',
    },
    maxWidth: '100%',
    wordBreak: 'break-all',
  },
}));

const AddressValue = ({ latitude, longitude, originalAddress }) => {
  const t = useTranslation();
  const classes = useStyles();

  const addressEnabled = useSelector((state) => state.session.server.geocoderEnabled);

  const [address, setAddress] = useState();

  useEffect(() => {
    setAddress(originalAddress);
  }, [latitude, longitude, originalAddress]);

  const showAddress = useCatch(async () => {
    const query = new URLSearchParams({ latitude, longitude });
    const response = await fetch(`/api/server/geocode?${query.toString()}`);
    if (response.ok) {
      setAddress(await response.text());
    } else {
      throw Error(await response.text());
    }
  });

  if (address) {
    return address;
  }
  if (addressEnabled) {
    return (<span className={classes.root} onClick={() => showAddress()} aria-hidden="true">{t('sharedShowAddress')}</span>);
  }
  return '';
};

export default AddressValue;

/* eslint-disable */

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  CardContent,
  Typography,
  CardActions,
  IconButton,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Menu,
  MenuItem,
  CardMedia,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import ReplayIcon from '@mui/icons-material/Replay';
import PublishIcon from '@mui/icons-material/Publish';
import PendingIcon from '@mui/icons-material/Pending';

import { useTranslation } from './LocalizationProvider';
import RemoveDialog from './RemoveDialog';
import PositionValue from './PositionValue';
import { useDeviceReadonly } from '../util/permissions';
import usePositionAttributes from '../attributes/usePositionAttributes';
import { devicesActions } from '../../store';
import { useCatch, useCatchCallback } from '../../reactHelper';
import { useAttributePreference } from '../util/preferences';
import { useTheme } from '@emotion/react';
import GetIgnitionStatus from '../../settings/components/getLockStatus';
import CommandsPopup from '../../custom/Commands';

const useStyles = makeStyles((theme) => ({
  card: {
    pointerEvents: 'auto',
    width: '100%',
    maxWidth: '330px',
    border: 'none',
    borderRadius: 'none',
    backgroundColor: theme.palette.colors.white,
    borderRadius: theme.dimensions.borderRadiusSmall,

    [theme.breakpoints.up('md')]: {
      boxShadow: theme.dimensions.boxShadow,
    },

    border: `1px solid ${theme.palette.colors.gray}`,
  },
  media: {
    height: theme.dimensions.popupImageHeight,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
  mediaButton: {
    color: theme.palette.colors.white,
    mixBlendMode: 'difference',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1, 1, 0, 2),
  },
  content: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  negative: {
    color: theme.palette.colors.negative,
  },
  icon: {
    width: '25px',
    height: '25px',
    filter: 'brightness(0) invert(1)',
  },
  table: {
    '& .MuiTableCell-sizeSmall': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  cell: {
    borderBottom: 'none',
  },
  actions: {
    justifyContent: 'space-around',
  },
}));

const StatusRow = ({ name, content }) => {
  const classes = useStyles();

  return (
    <TableRow>
      <TableCell className={classes.cell}>
        <Typography variant="body2">{name}</Typography>
      </TableCell>
      <TableCell className={classes.cell}>
        <Typography variant="body2" color="textSecondary">
          {content}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

const StatusCard = ({
  deviceId,
  position,
  onClose,
  disableActions,
  desktopPadding = 0,
}) => {
  const classes = useStyles({ desktopPadding });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const t = useTranslation();
  const theme = useTheme();

  const device = useSelector((state) => state.devices.items[deviceId]);

  const deviceImage = device?.attributes?.deviceImage;

  const positionAttributes = usePositionAttributes(t);
  const positionItems = useAttributePreference(
    'positionItems',
    'speed,address,totalDistance,course,battery,motion,odometer'
  );

  const [anchorEl, setAnchorEl] = useState(null);

  const [removing, setRemoving] = useState(false);
  const [showCommands, setShowCommands] = useState(false);

  const handleRemove = useCatch(async (removed) => {
    if (removed) {
      const response = await fetch('/api/devices');
      if (response.ok) {
        dispatch(devicesActions.refresh(await response.json()));
      } else {
        throw Error(await response.text());
      }
    }
    setRemoving(false);
  });
  const handleGeofence = useCatchCallback(async () => {
    const newItem = {
      name: '',
      area: `CIRCLE (${position.latitude} ${position.longitude}, 50)`,
    };
    const response = await fetch('/api/geofences', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(newItem),
    });
    if (response.ok) {
      const item = await response.json();
      const permissionResponse = await fetch('/api/permissions', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          deviceId: position.deviceId,
          geofenceId: item.id,
        }),
      });
      if (!permissionResponse.ok) {
        throw Error(await permissionResponse.text());
      }
      navigate(`/settings/geofence/${item.id}`);
    } else {
      throw Error(await response.text());
    }
  }, [navigate, position]);

  useEffect(() => {
    setShowCommands(false);
  }, [device])

  return (
    <>
      {device && (
        <div elevation={3} className={classes.card}>
          {deviceImage ? (
            <CardMedia
              className={classes.media}
              image={`/api/media/${device.uniqueId}/${deviceImage}`}
            >
              {position?.attributes && <GetIgnitionStatus attributes={position.attributes} />}

              <IconButton size="small" onClick={onClose} onTouchStart={onClose}>
                <CloseIcon fontSize="small" className={classes.mediaButton} />
              </IconButton>
            </CardMedia>
          ) : (
            <div className={classes.header}>
              <Typography variant="body2" color="textSecondary">
                {device.name}
              </Typography>
              {position?.attributes && <GetIgnitionStatus attributes={position.attributes} />}

              <IconButton size="small" onClick={onClose} onTouchStart={onClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
          )}

          {position && (
            <CardContent className={classes.content}>
              <Table size="small" classes={{ root: classes.table }}>
                <TableBody>
                  {positionItems
                    .split(',')
                    .filter(
                      (key) =>
                        position.hasOwnProperty(key) ||
                        position.attributes.hasOwnProperty(key)
                    )
                    .map((key) => (
                      <StatusRow
                        key={key}
                        name={
                          positionAttributes.hasOwnProperty(key)
                            ? positionAttributes[key].name
                            : key
                        }
                        content={
                          <PositionValue
                            position={position}
                            property={position.hasOwnProperty(key) ? key : null}
                            attribute={
                              position.hasOwnProperty(key) ? null : key
                            }
                          />
                        }
                      />
                    ))}
                </TableBody>
              </Table>
              <CommandsPopup device={device} />
            </CardContent>
          )}
          <CardActions classes={{ root: classes.actions }} disableSpacing>
            <IconButton
              onClick={(e) => setAnchorEl(e.currentTarget)}
              disabled={!position}
            >
              <PendingIcon />
            </IconButton>
            {/* <IconButton
              color="primary"
              sx={{
                borderBottom: `5px solid ${theme.palette.primary.main}`,
                transform: showCommands ? 'scaleY(-1)' : 'scaleY(1)', // Flip vertically if showCommands is true
              }}
              onClick={() => {
                setShowCommands(!showCommands);
              }}
              disabled={disableActions || !position}
            >
              <PublishIcon fontSize="large" />
            </IconButton> */}
            <IconButton
              onClick={() => navigate('/replay')}
              disabled={disableActions || !position}
            >
              <ReplayIcon />
            </IconButton>
          </CardActions>
        </div>
      )}
      {position && (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem onClick={() => navigate(`/position/${position.id}`)}>
            <Typography color="secondary">{t('sharedShowDetails')}</Typography>
          </MenuItem>
          <MenuItem onClick={handleGeofence}>
            {t('sharedCreateGeofence')}
          </MenuItem>
          <MenuItem
            component="a"
            target="_blank"
            href={`https://www.google.com/maps/search/?api=1&query=${position.latitude}%2C${position.longitude}`}
          >
            {t('linkGoogleMaps')}
          </MenuItem>
          {/* <MenuItem
            component="a"
            target="_blank"
            href={`http://maps.apple.com/?ll=${position.latitude},${position.longitude}`}
          >
            {t('linkAppleMaps')}
          </MenuItem> */}
          <MenuItem
            component="a"
            target="_blank"
            href={`https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${position.latitude}%2C${position.longitude}&heading=${position.course}`}
          >
            {t('linkStreetView')}
          </MenuItem>
          <MenuItem
            onClick={() => {
              !disableActions || position
                ? navigate(`/devices/${deviceId}`)
                : '';
            }}
          >
            <Typography>Edit Device</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              !disableActions || position ? setRemoving(true) : '';
            }}
            className={classes.negative}
          >
            <Typography>Remove Device</Typography>
          </MenuItem>
        </Menu>
      )}
      <RemoveDialog
        open={removing}
        endpoint="devices"
        itemId={deviceId}
        onResult={(removed) => handleRemove(removed)}
      />
    </>
  );
};



export default StatusCard;

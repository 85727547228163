/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { devicesActions } from '../store';
import { useEffectAsync } from '../reactHelper';
import DeviceRow from './DeviceRow';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('md')]: {
      background: theme.palette.colors.white,
    },
    [theme.breakpoints.down('md')]: {
      background: 'transparent',
    },
    paddingBottom: theme.spacing(3),
    width: '360px',
    height: '100%',
    '&:hover': {
      background: theme.palette.colors.white,
    },
    display: 'flex',
  },
  list: {
    maxHeight: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    overflowY: 'scroll',
  },
  listContainer: {
    flexGrow: 1, // Allow the container to grow and take available space
  },
}));

const DeviceList = ({ devices }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [, setTime] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffectAsync(async () => {
    const response = await fetch('/api/devices');
    if (response.ok) {
      dispatch(devicesActions.refresh(await response.json()));
    } else {
      throw Error(await response.text());
    }
  }, []);

  return (
    <div className={classes.root}>
      <AutoSizer>
        {({ height, width }) => (
          <div className={classes.listContainer}>
            <FixedSizeList
              height={height}
              width={width}
              itemCount={devices.length}
              itemSize={80}
            >
              {({ index, style }) => (
                <div style={style}>
                  <DeviceRow
                    data={devices}
                    index={index}
                    key={devices[index].id}
                  />
                </div>
              )}
            </FixedSizeList>
          </div>
        )}
      </AutoSizer>
    </div>
  );
};

export default DeviceList;

{
  /* <AutoSizer className={classes.list}>
        {({ height, width }) => (
          <FixedSizeList
            width={width}
            height={height * 1.2}
            itemCount={devices.length}
            itemData={devices}
            itemSize={100}
            overscanCount={100}
            innerRef={listInnerEl}
          > */
}
{
  /* {DeviceRow} */
}
{
  /* </FixedSizeList>
        )}
      </AutoSizer> */
}

/* eslint-disable */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import {
  IconButton,
  Tooltip,
  Avatar,
  ListItemAvatar,
  ListItemText,
  ListItemButton,
} from '@mui/material';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import Battery60Icon from '@mui/icons-material/Battery60';
import BatteryCharging60Icon from '@mui/icons-material/BatteryCharging60';
import Battery20Icon from '@mui/icons-material/Battery20';
import BatteryCharging20Icon from '@mui/icons-material/BatteryCharging20';
import ErrorIcon from '@mui/icons-material/Error';
import moment from 'moment';
import { devicesActions } from '../store';
import {
  formatAlarm,
  formatBoolean,
  formatPercentage,
  formatSpeed,
  formatStatus,
  getStatusColor,
} from '../common/util/formatter';
import { useTranslation } from '../common/components/LocalizationProvider';
import { useAdministrator } from '../common/util/permissions';
import { ReactComponent as EngineIcon } from '../resources/images/data/engine.svg';
import { useAttributePreference } from '../common/util/preferences';

const useStyles = makeStyles((theme) => ({
  listItem: {
    [theme.breakpoints.up('md')]: {
      margin: `${theme.spacing(1)} ${theme.spacing(2)}`,
    },
    [theme.breakpoints.down('md')]: {
      margin: `${theme.spacing(1)} 0`,
    },
    border: `1px solid ${theme.palette.colors.gray}`,
    borderRadius: theme.dimensions.borderRadiusSmall,
    boxShadow: theme.dimensions.boxShadow,
    background: theme.palette.colors.white,
    '&:hover': {
      background: theme.palette.colors.white,
    },
  },
  itemRight: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  secondaryText: {
    fontSize: '0.75rem',
    color: theme.palette.colors.darkGray,
  },
  online: {
    borderLeft: '4px solid green',
  },
  offline: {
    borderLeft: '4px solid red',
  },
  icon: {
    width: '25px',
    height: '25px',
    filter: 'brightness(0) invert(1)',
  },
  batteryText: {
    fontSize: '0.75rem',
    fontWeight: 'normal',
    lineHeight: '0.875rem',
  },
  positive: {
    color: theme.palette.colors.positive,
  },
  medium: {
    color: theme.palette.colors.medium,
  },
  negative: {
    color: theme.palette.colors.negative,
  },
  neutral: {
    color: theme.palette.colors.neutral,
  },
}));

const DeviceRow = ({ data, index }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const t = useTranslation();

  const admin = useAdministrator();

  const item = data[index];
  const position = useSelector((state) => state.session.positions[item.id]);

  const speedUnit = useAttributePreference('speedUnit');
  const devicePrimary = useAttributePreference('devicePrimary', 'name');
  const deviceSecondary = useAttributePreference('deviceSecondary', '');
  const secondaryText = () => {
    let status;
    if (item.status === 'online' || !item.lastUpdate) {
      status = formatStatus(item.status, t);
    } else {
      status = moment(item.lastUpdate).fromNow();
      // const statusObj = new Date(item.lastUpdate);
      // status = `${statusObj.toLocaleDateString()} ${statusObj.toLocaleTimeString()}`;
    }
    return (
      // <>
      //   {deviceSecondary &&
      //     item[deviceSecondary] &&
      //     `${item[deviceSecondary]} • `}
      //     {position?.hasOwnProperty('speed') &&
      //       formatSpeed(position.speed, speedUnit, t)}
      //   <br />
      // <span className={classes[getStatusColor(item.status)]}>{status}</span>
      <span className={classes.secondaryText}>{status}</span>
      // </>
    );
  };

  const getSpeed = () => (
    <span>
      {deviceSecondary &&
        item[deviceSecondary] &&
        `${item[deviceSecondary]} • `}
      {position?.hasOwnProperty('speed') &&
        formatSpeed(position.speed, speedUnit, t)}
    </span>
  );

  const isOnline = () => {
    if (item.status === 'online') {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      <ListItemButton
        key={item.id}
        className={`${classes.listItem} ${
          isOnline() ? classes.online : classes.offline
        }`}
        onClick={() => {
          dispatch(devicesActions.selectId(item.id));
            dispatch(devicesActions.setShowCommands(false))
        }}
        disabled={!admin && item.disabled}
      >
        {/* <ListItemAvatar>
          <Avatar>
            <img
              className={classes.icon}
              src={mapIcons[mapIconKey(item.category)]}
              alt=""
            />
          </Avatar>
        </ListItemAvatar> */}
        {/* <div>{secondaryText()}</div> */}
        <ListItemText
          primary={item[devicePrimary]}
          secondary={secondaryText()}
          secondaryTypographyProps={{ noWrap: true }}
        />
        <div className={classes.itemRight}>
          <span className={classes.secondaryText}>{getSpeed()}</span>
          {position && (
            <div style={{ display: 'flex', gap: 1 }}>
              {position.attributes.hasOwnProperty('alarm') && (
                <Tooltip
                  title={`${t('eventAlarm')}: ${formatAlarm(
                    position.attributes.alarm,
                    t
                  )}`}
                >
                  <IconButton size="small">
                    <ErrorIcon fontSize="small" className={classes.negative} />
                  </IconButton>
                </Tooltip>
              )}

              {position.attributes.hasOwnProperty('ignition') && (
                <Tooltip
                  title={`${t('positionIgnition')}: ${formatBoolean(
                    position.attributes.ignition,
                    t
                  )}`}
                >
                  <IconButton size="small">
                    {position.attributes.ignition ? (
                      <EngineIcon
                        width={20}
                        height={20}
                        className={classes.positive}
                      />
                    ) : (
                      <EngineIcon
                        width={20}
                        height={20}
                        className={classes.neutral}
                      />
                    )}
                  </IconButton>
                </Tooltip>
              )}
              {position.attributes.hasOwnProperty('batteryLevel') && (
                <Tooltip
                  title={`${t('positionBatteryLevel')}: ${formatPercentage(
                    position.attributes.batteryLevel
                  )}`}
                >
                  <IconButton size="small">
                    {position.attributes.batteryLevel > 70 ? (
                      position.attributes.charge ? (
                        <BatteryChargingFullIcon
                          fontSize="small"
                          className={classes.positive}
                        />
                      ) : (
                        <BatteryFullIcon
                          fontSize="small"
                          className={classes.positive}
                        />
                      )
                    ) : position.attributes.batteryLevel > 30 ? (
                      position.attributes.charge ? (
                        <BatteryCharging60Icon
                          fontSize="small"
                          className={classes.medium}
                        />
                      ) : (
                        <Battery60Icon
                          fontSize="small"
                          className={classes.medium}
                        />
                      )
                    ) : position.attributes.charge ? (
                      <BatteryCharging20Icon
                        fontSize="small"
                        className={classes.negative}
                      />
                    ) : (
                      <Battery20Icon
                        fontSize="small"
                        className={classes.negative}
                      />
                    )}
                  </IconButton>
                </Tooltip>
              )}
            </div>
          )}
        </div>
      </ListItemButton>
    </div>
  );
};

export default DeviceRow;

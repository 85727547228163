/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useCatch } from '../reactHelper';
import { useRestriction } from '../common/util/permissions';
import { useTranslation } from '../common/components/LocalizationProvider';
import { devicesActions } from '../store';
import SelectField from '../common/components/SelectField';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const useStyles = makeStyles((theme) => ({
  buttons: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    gap: theme.spacing(1),
    justifyContent: 'space-between',
    width: '100%',
  },
  pressed: {
    background: theme.palette.primary.main,
    margin: `${theme.spacing(1)} 0 5px 0`,
    borderBottom: 0,
  },
}));

const CommandsPopup = React.memo(({ device }) => {
  const dispatch = useDispatch();

  const classes = useStyles();
  const t = useTranslation();

  const [savedId, setSavedId] = useState(0);
  const [success, setSuccess] = useState(false);

  const item = {};

  const limitCommands = useRestriction('limitCommands');

  const handleSend = useCatch(async () => {
    let command;
    if (savedId) {
      const response = await fetch(`/api/commands/${savedId}`);
      if (response.ok) {
        command = await response.json();
      } else {
        throw Error(await response.text());
      }
    } else {
      command = item;
    }

    if (!command.textChannel) handleGPRS(command);
    if (command.textChannel) handleSMS(command.attributes.data);
  });

  const handleGPRS = async (command) => {
    command.deviceId = device.deviceId;

    const response = await fetch('/api/commands/send', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(command),
    });

    if (response.ok) {
      setSuccess(true);
    } else {
      throw Error(await response.text());
    }
  };

  const handleSMS = async (command) => {
    const data = { command };
    const response = await fetch(
      `https://api.eu.production.rentvisie.com/api/v2/traci/sms/${device.phone}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }
    );
    if (response.ok) {
      setSuccess(true)
    } else {
      throw Error(await response.text());
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setSuccess(false);
    }, 2000)
  }, [success])

  const validate = () => savedId || (item && item.type);

  return (
      <div>
        <SelectField
          value={savedId}
          emptyValue={limitCommands ? null : 0}
          emptyTitle={'...'}
          onChange={(e) => {
            setSavedId(e.target.value);
          }}
          useStyles={useStyles}
          endpoint={`/api/commands/send?deviceId=${device.id}`}
          titleGetter={(it) => it.description}
          label={t('commands')}
          fullWidth={true}
        ></SelectField>

        <div className={classes.buttons}>
          <Button
            type="button"
            color={success ? "success" : "primary"}
            fullWidth
            variant="contained"
            onClick={handleSend}
            disabled={!validate()}
          >
            {success ? <CheckCircleIcon /> : t('commandSend')}
          </Button>
        </div>
      </div>
  );
});

export default CommandsPopup;
